<template>
    <el-container class="page-container">
        <!--<el-header class="page-header" style="min-height: 60px;height: auto">-->

        <!--</el-header>-->
        <el-main class="page-main" style="padding: 0 20px">
            <br>
            <el-form label-position='left' label-width="80px" :model="query" ref="searchForm">
                <el-row :gutter="20">
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item label-width="80px" label="网吧编码" prop="code">
                            <el-input v-model="query.code" placeholder="请输入网吧编码"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item label-width="80px" label="网吧名称" prop="name">
                            <el-input v-model="query.name" placeholder="请输入网吧名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item label-width="80px" label="联系电话" prop="mobile">
                            <el-input v-model="query.mobile" placeholder="请输入联系电话"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6">
                        <el-form-item label-width="80px" label="网吧状态" prop="spStatus">
                            <el-select v-model="query.spStatus" placeholder="查询类型">
                                <el-option label="全部" value=""></el-option>
                                <el-option label="正常" value="1"></el-option>
                                <el-option label="禁用" value="-1"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="24">
                        <el-form-item label-width="0">
                            <el-button type="primary" @click="handleSearch">
                                <i class="cw-icon cw-icon-sousuo"></i>搜索
                            </el-button>
                            <el-button type="primary" plain @click="clearSearch">
                                <i class="cw-icon cw-icon-zhongzhi"></i>重置
                            </el-button>
                            <el-button type="primary" @click="handleAdd('新增')">
                                <i class="cw-icon cw-icon-tianjia"></i>新增
                            </el-button>
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-form>

            <el-row class="row-btn-group">

                <!--                <el-button type="danger" @click="doDelMore" :disabled="selectData.length==0">-->
                <!--                    <i class="cw-icon cw-icon-tianjia"></i>删除选中-->
                <!--                </el-button>-->
            </el-row>
            <el-table
                    :data="tableData"
                    ref="multipleTable"
                    @selection-change="handleSelectionChange"
                    style="width: 100%">
                <el-table-column
                        type="selection"
                        width="55">
                </el-table-column>
                <el-table-column
                        prop="code"
                        label="编码"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="sptype"
                        label="类型"
                        :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                        <span v-if="scope.row.sptype==1">网吧</span>
                        <span v-if="scope.row.sptype==2">传单</span>
                    </template>
                </el-table-column>

                <el-table-column
                        prop="name"
                        label="名称"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="mobile"
                        label="联系电话"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="address"
                        label="地址"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="sp_status"
                        label="状态"
                        :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                        <p v-if="scope.row.sp_status==-1">停用</p>
                        <p v-if="scope.row.sp_status==1">正常</p>
                    </template>
                </el-table-column>


                <el-table-column
                        prop="reward_type"
                        label="返点类型"
                        :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                        <p v-if="scope.row.reward_type==1">现结</p>
                        <p v-if="scope.row.reward_type==2">月结</p>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="settle_type"
                        label="结算方式"
                        :show-overflow-tooltip="true"
                >
                    <template slot-scope="scope">
                        <p v-if="scope.row.settle_type==1">按单量</p>
                        <p v-if="scope.row.settle_type==2">按商品数量</p>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="ret_fee"
                        label="返点"
                        :show-overflow-tooltip="true"
                >

                </el-table-column>
                <el-table-column
                        prop="price"
                        label="员工餐价格"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>
                <el-table-column
                        prop="order_num"
                        label="排序"
                        :show-overflow-tooltip="true"
                >
                </el-table-column>


                <!--                <el-table-column-->
                <!--                        fixed="right"-->
                <!--                        width="260"-->
                <!--                        label="操作">-->
                <!--                    <template slot-scope="scope">-->
                <!--                        <el-button size="mini" type="success" @click="getErcode(scope.row)"  plain>获取小程序码</el-button>-->
                <!--                        <el-button size="mini" type="success" @click="handleEdit(scope.row)" plain>修改</el-button>-->
                <!--                        <el-button size="mini" type="success" @click="doDelete(scope.row)" plain>删除</el-button>-->
                <!--                    </template>-->
                <!--                </el-table-column>-->
                <el-table-column
                        prop="id"
                        label="操作"
                        width="115">
                    <template slot-scope="scope">
                        <el-dropdown @command="dropdownCommand">
                            <el-button size="mini">
                                <i class="el-icon-setting"></i>
                                操作
                                <span class="el-icon-arrow-down"></span>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item :command="{type:'1',row:scope.row}">
                                    获取小程序码
                                </el-dropdown-item>
                                <el-dropdown-item
                                        :command="{type:'2',row:scope.row}">
                                    修改
                                </el-dropdown-item>
                                <el-dropdown-item
                                        :command="{type:'3',row:scope.row}">
                                    删除
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
            <br>
            <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageInfo.pageNumber"
                    :page-sizes="[10, 20, 30, 40]"
                    :page-size="pageInfo.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.totalCount">
            </el-pagination>
        </el-main>
        <!--//编辑管理员-->
        <form-dialog @close="handleClose('editForm')" :title="editTitle" :dialogVisible="visibleFormDialog">
            <template slot="content">
                <el-form :model="formData" ref="editForm" :rules="formRules" label-position="right" label-width="95px">
                    <el-form-item prop="code" label="编码">
                        <el-input maxlength="10" placeholder="编码"
                                  v-model="formData.code"
                                  auto-complete="off" tabindex="3" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="name" label="名称">
                        <el-input maxlength="100" placeholder="名称"
                                  v-model="formData.name"
                                  auto-complete="off" tabindex="3" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="spType" label="类型">
                        <el-radio-group v-model="formData.spType">
                            <el-radio :label="1" border>网吧</el-radio>
                            <el-radio :label="2" border>传单</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item prop="mobile" label="联系电话">
                        <el-input maxlength="100" placeholder="联系电话"
                                  v-model="formData.mobile"
                                  auto-complete="off" tabindex="4" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="address" label="详细地址">
                        <el-input maxlength="100" placeholder="详细地址"
                                  v-model="formData.address"
                                  auto-complete="off" tabindex="9" type="text"></el-input>
                    </el-form-item>
                    <el-form-item prop="spStatus" label="状态">
                        <el-radio-group v-model="formData.spStatus">
                            <el-radio :label="1" border>正常</el-radio>
                            <el-radio :label="-1" border>停用</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item prop="rewardType" label="返点类型">
                        <el-radio-group v-model="formData.rewardType">
                            <el-radio :label="1" border>现结</el-radio>
                            <el-radio :label="2" border>月结</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item prop="settleType" label="结算方式">
                        <el-radio-group v-model="formData.settleType">
                            <el-radio :label="1" border>按单量</el-radio>
                            <el-radio :label="2" border>按商品数量</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item prop="retFee" label="返点值">
                        <el-input maxlength="100" placeholder="返点值"
                                  v-model="formData.retFee"
                                  max="1"
                                  min="0"
                                  auto-complete="off" tabindex="9" type="number"></el-input>
                    </el-form-item>
                    <el-form-item prop="price" label="员工餐价格">
                        <el-input maxlength="100" placeholder="员工餐价格"
                                  v-model="formData.price"
                                  auto-complete="off" tabindex="9" type="number"></el-input>
                    </el-form-item>
                    <el-form-item prop="orderNum" label="排序数字">
                        <el-input maxlength="100" placeholder="排序数字"
                                  v-model="formData.orderNum"
                                  auto-complete="off" tabindex="9" type="number"></el-input>
                    </el-form-item>
                    <div class="flex flex-direction-row-reverse btn-group">
                        <el-button type="primary" @click="doEdit">确 定</el-button>
                        <el-button @click="closeModul">取 消</el-button>
                    </div>
                </el-form>

            </template>
        </form-dialog>
        <form-dialog @close="handleClose('codeForm')" title="商家小程序码" :dialogVisible="codeDialogVisible" width="40%">
            <template slot="content" class="loadingtext">
                <el-form ref="codeForm" size="small" label-width="80px" align="center">

                    <img :src="formData.shop_qrcode" style="width:250px;height:250px" v-if="formData.shop_qrcode"/>
                    <p v-else style="width:100%;height:250px;line-height: 250px;text-align: center">加载中...</p>
                    <div class="flex flex-direction-row-reverse btn-group">
                        <el-button type="primary" @click="doDownload">下载</el-button>
                        <el-button @click="codeDialogVisible = false">取 消</el-button>
                    </div>
                </el-form>
            </template>
        </form-dialog>
    </el-container>

</template>

<script>
import {mapGetters} from 'vuex'
import formDialog from '../../components/formDialog'
import {shopData, shopAdd, shopEdit, shopDeleteOne, shopDeleteMore, getErcode} from '../../api/firmManage'
import moment from 'moment'

export default {
    name: "companyManagement",
    components: {formDialog},
    data() {
        let validatemobile = (rule, value, callback) => {
            if ((value && !(/^1[3456789]\d{9}$/.test(value)))) {
                callback(new Error('请输入正确的联系电话'));
            } else {
                callback();
            }
        };
        let validateretFee = (rule, value, callback) => {
            console.log(205, this.formData.retFee)
            if (!this.formData.retFee) {
                callback(new Error('返点值'));
            } else if (this.formData.retFee > 1) {
                callback(new Error('返点值应小于100%'));
            } else {
                callback();
            }
        };
        return {
            tableData: [],
            pageInfo: {
                totalCount: 0,
                pageNumber: 1,
                pageSize: 10
            },
            query: {},
            visibleFormDialog: false,
            // formData: {
            //   name:'',//名字
            //   spType:'',//类型
            //   mobile:'',//电话号码
            //   address:'',//地址
            // },
            formData: {
                code: '',//商家code
                spType: '',//类型
                name: '',//名称
                mobile: '',//电话
                address: '',//地址
                // longitude:null,//
                // latitude:null,
                spStatus: '',//状态
                rewardType: '',//返点类型
                settleType: '',//结算方式
                retFee: '',//返点金额
                // shopQrCode:'',//二维码地址
                price: '',//价格
                orderNum: ''//排序数字
            },
            editTitle: '',
            formRules: {
                code: [
                    {required: true, message: '编码', trigger: 'blur'}
                ],
                // retFee: [
                //     {required: true,validator: validateretFee,  trigger: 'blur'}
                // ],
                // price: [
                //     {required: true, message: '员工餐价格', trigger: 'blur'}
                // ],
                orderNum: [
                    {required: true, message: '排序数字', trigger: 'blur'}
                ],
                name: [
                    {required: true, message: '名称', trigger: 'blur'}
                ],
                spType: [
                    {required: true, message: '类型', trigger: 'blur'}
                ],
                rewardType: [
                    {required: true, message: '返点类型', trigger: 'blur'}
                ],
                settleType: [
                    {required: true, message: '结算方式', trigger: 'blur'}
                ],
                mobile: [
                    {validator: validatemobile, trigger: 'blur'}
                ],
                address: [
                    {required: true, message: '详细地址', trigger: 'blur'}
                ],
                spStatus: [
                    {required: true, message: '状态', trigger: 'blur'}
                ],
            },
            selectData: [],
            codeDialogVisible: false,
            shopErcode: {},
        }
    },
    computed: {
        ...mapGetters([
            'userInfo'
        ]),
    },
    methods: {
        handleSearch() {
            this.pageInfo.pageNumber = 1;
            this.shopData()
        },
        async shopData() {
            try {
                let query = Object.assign({}, this.pageInfo, this.query)
                let formData = {}
                for (let i in query) {
                    if (query[i] !== '' && i !== 'total' && query[i] !== -1) {
                        formData[i] = query[i]
                    }
                }
                let resonse = await shopData(formData)
                let {data} = resonse;
                this.tableData = data.list;
                this.pageInfo.totalCount = data.totalCount;
            } catch (e) {
                console.log(e)
            }
        },
        clearSearch() {
            this.query = {}
            this.handleSearch()
        },
        handleSizeChange(val) {
            this.pageInfo.pageSize = val
            this.pageInfo.page = 1
            this.shopData()
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            this.pageInfo.pageNumber = val
            this.shopData()
        },
        handleClose(form) {
            this.$refs[form].resetFields();
            this.visibleFormDialog = false;
            this.codeDialogVisible = false;
        },
        handleAdd() {
            this.editTitle = '新建商家';
            this.visibleFormDialog = true;
            this.formData = {};
            this.$refs.searchForm.resetFields();
        },
        handleEdit(row) {
            // console.log(426,row)
            this.editTitle = '修改商家';
            let formData = {}
            let formKey = {
                id: '',
                code: '',//商家code
                spType: '',//类型
                name: '',//名称
                mobile: '',//电话
                address: '',//地址
                // longitude:null,//
                // latitude:null,
                spStatus: '',//状态
                rewardType: '',//返点类型
                settleType: '',//结算方式
                retFee: '',//返点金额
                // shopQrCode:'',//二维码地址
                price: '',//价格
                orderNum: ''//排序数字
            }
            let newFormData = {}
            formData = row
            formData.spType = +row.sptype;
            formData.spStatus = +row.sp_status;
            formData.rewardType = +row.reward_type;
            formData.settleType = +row.settle_type;
            formData.retFee = +row.ret_fee;
            formData.orderNum = +row.order_num;
            for (let i in formKey) {
                newFormData[i] = formData[i]
            }
            this.formData = newFormData;
            this.visibleFormDialog = true;
        },
        //修改
        async doEdit() {
            // console.log(271,this.formData)
            this.$refs['editForm'].validate(async (valid) => {
                if (valid) {
                    try {
                        this.formData.id ? await shopEdit(this.formData) : await shopAdd(this.formData);
                        this.$message({
                            type: 'success',
                            message: '操作成功!'
                        });
                        this.visibleFormDialog = false;
                        this.$refs['editForm'].resetFields();
                        this.shopData()
                    } catch (e) {

                    }
                }
            })
        },
        //删除
        async doDelete(row) {
            console.log(308, row);
            this.$confirm('删除后无法恢复，确定删除' + row.name + '吗?', '确定', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                try {
                    await shopDeleteOne(row.id);
                    this.$message({
                        type: 'success',
                        message: '更新成功!'
                    });
                    this.shopData()
                } catch (e) {
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        handleSelectionChange(val) {
            console.log(334, val);
            this.selectData = val;
        },
        closeModul() {
            this.visibleFormDialog = false;
            this.shopData();
        },
        async doDelMore() {
            let ids = [];
            this.selectData.forEach(function (val) {
                ids.push(val.id);
            });
            this.$confirm('删除后无法恢复，确定删除选中选项吗?', '确定', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                try {
                    await shopDeleteMore({ids: ids.toString()});
                    this.$message({
                        type: 'success',
                        message: '更新成功!'
                    });
                    this.shopData()
                } catch (e) {
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        async getErcode(row) {
            console.log(483, row)
            this.formData = row;
            this.codeDialogVisible = true;
            // this.shopErcode={};
            // try {
            //   this.codeDialogVisible=true;
            //   let {data}=await getErcode(row.id);
            //   this.shopErcode=data;
            // }catch (e) {
            //
            // }
        },
        doDownload() {
            let self = this;
            let a = document.createElement('a');
            a.download = self.shopName || 'wxcode';
            // 设置图片地址
            a.href = self.formData.shop_qrcode;
            a.click();
        },
        async dropdownCommand(command) {//监听下拉框事件
            console.log(529, command);
            if (command.type == 1) {
                await this.getErcode(command.row);
                // this.formData=command.row;
            } else if (command.type == 2) {
                await this.handleEdit(command.row);
            } else {
                await this.doDelete(command.row);
            }
        },
    },
    mounted() {
        this.shopData()
    }
}
</script>

<style lang="less">
.btn-group {
    button {
        margin-left: 20px;
    }

}

.el-input--prefix .el-input__inner {
    padding-left: 46px;
}

.el-input__prefix {
    left: 15px;
    color: #303133;

    i {
        font-style: normal;
    }
}

.icon-cursor {
    cursor: pointer;
    margin-right: 10px;

    &:hover {
        color: #348EED;
    }
}

.avatar-uploader {
    .el-upload {
        border: 1px solid #DCDFE6;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 72px;
        display: block;

        &:hover {
            border-color: #409EFF;
        }
    }

    .cw-icon-tupian {
        font-size: 24px;
        color: #c0c4cc;
        width: 72px;
        height: 72px;
        line-height: 72px;
        text-align: center;
    }

    .avatar {
        width: 72px;
        height: 72px;
        display: block;
    }
}

</style>
